var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shipping" },
    [
      _c("header-label", {
        attrs: {
          label: _vm.$t("shipping.header"),
          "prepend-icon": "$g_shipping"
        }
      }),
      _c(
        "v-tabs",
        {
          staticClass: "app-tab",
          attrs: { "show-arrows": "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab", [
            _vm._v(" " + _vm._s(_vm.$t("shipping.tab.management")) + " ")
          ]),
          _vm.permission.settingShippingCalculator
            ? _c("v-tab", [
                _vm._v(" " + _vm._s(_vm.$t("shipping.tab.calculate")) + " ")
              ])
            : _vm._e(),
          _c(
            "v-tab-item",
            [
              _c("shipping-manage", {
                on: {
                  input: function($event) {
                    return _vm.refetchShippingCalculator()
                  },
                  submit: function($event) {
                    return _vm.refetchShippingCalculator()
                  }
                }
              })
            ],
            1
          ),
          _vm.permission.settingShippingCalculator
            ? _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("calculate-data-table", {
                            ref: "CaculateDataTable"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }