<template>
  <div class="shipping">
    <header-label
      :label="$t('shipping.header')"
      prepend-icon="$g_shipping" />
    <v-tabs
      v-model="tab"
      class="app-tab"
      show-arrows>
      <v-tab>
        {{ $t('shipping.tab.management') }}
      </v-tab>
      <v-tab v-if="permission.settingShippingCalculator">
        {{ $t('shipping.tab.calculate') }}
      </v-tab>
      <v-tab-item>
        <shipping-manage
          @input="refetchShippingCalculator()"
          @submit="refetchShippingCalculator()" />
      </v-tab-item>
      <v-tab-item v-if="permission.settingShippingCalculator">
        <v-card flat>
          <v-card-text>
            <calculate-data-table ref="CaculateDataTable" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ShippingManage from '@/views/Shipping/components/ShippingManage/ShippingManage.vue'
import CalculateDataTable from '@/views/Shipping/components/ShippingCalculate/CalculateDataTable'

export default {
  name: 'Shipping',
  components: {
    ShippingManage,
    CalculateDataTable
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    ...mapGetters({
      permission: 'Auth/permission'
    })
  },
  methods: {
    refetchShippingCalculator () {
      if (this.permission.settingShippingCalculator) {
        this.$refs.CaculateDataTable.refetch()
      }
    }
  }
}
</script>

<style>
  .v-window__container {
    background: #F1F2F5;
  }
</style>
