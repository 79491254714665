var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("text-field-label", {
              staticClass: "mr-3",
              attrs: { label: _vm.$t("shipping.shippingConnection.J&T.title") }
            }),
            _vm.expand
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      href:
                        "https://www." + _vm.domainName + "/jt_instruction.png",
                      target: "_blank",
                      color: "primary",
                      outlined: ""
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("shipping.btn.howto")) + " ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _vm.jtName && _vm.jtCode
              ? _c("text-field-label", {
                  staticClass: "mr-2",
                  attrs: { label: _vm.$t("shipping.btn.active") }
                })
              : _c("text-field-label", {
                  staticClass: "mr-2",
                  attrs: { label: _vm.$t("shipping.btn.close") }
                }),
            _c(
              "v-btn",
              {
                staticClass: "mb-1 mr-6",
                attrs: { color: "primary", text: "" },
                on: {
                  click: function($event) {
                    _vm.expand = !_vm.expand
                  }
                }
              },
              [
                _vm.expand
                  ? _c("text-field-label", {
                      staticClass: "mt-2",
                      staticStyle: {
                        color: "var(--v-primary-base) !important"
                      },
                      attrs: { label: _vm.$t("shipping.btn.cancel") }
                    })
                  : _c("text-field-label", {
                      staticClass: "mt-2",
                      staticStyle: {
                        color: "var(--v-primary-base) !important"
                      },
                      attrs: { label: _vm.$t("shipping.btn.active") }
                    })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("v-expand-transition", [
        _vm.expand
          ? _c(
              "div",
              [
                _c(
                  "v-row",
                  {
                    staticClass: "pl-12 mt-4 mb-4",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pl-8", attrs: { cols: "4" } },
                      [
                        _c("text-field-label", {
                          attrs: {
                            label: _vm.$t(
                              "shipping.shippingConnection.J&T.name"
                            )
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "input-readonly",
                          staticStyle: { "max-width": "300px" },
                          attrs: {
                            value: _vm.jtName,
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            readonly: ""
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "pl-12 mt-4 mb-4",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pl-8", attrs: { cols: "4" } },
                      [
                        _c("text-field-label", {
                          attrs: {
                            label: _vm.$t(
                              "shipping.shippingConnection.J&T.code"
                            )
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "input-readonly",
                          staticStyle: { "max-width": "300px" },
                          attrs: {
                            value: _vm.jtCode,
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            readonly: ""
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "pl-12 mt-4 mb-4",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pl-8", attrs: { cols: "4" } },
                      [
                        _c("register-jt", {
                          attrs: {
                            name: _vm.jtName,
                            sid: _vm.jtCode,
                            address: _vm.jtAddress
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }