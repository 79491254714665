<template>
  <div class="shipping-manage">
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <title-label
          :label="$t('shipping.selectShipping.title')"
          prepend-icon="$transportTitle" />
        <v-row class="pl-12">
          <v-col cols="3">
            <text-field-label
              :label="$t('shipping.selectShipping.shippingTypes')">
            </text-field-label>
          </v-col>
          <v-col>
            <div class="d-flex">
              <div class="shipping-type mr-4">
                <v-chip
                  v-for="(shipping, index) in setting.shippingTypes"
                  :key="`shipping_types-${shipping}-${index}`"
                  color="primary"
                  class="mb-2 mr-2"
                  close
                  label
                  @click:close="removeShippingTypes(index)">
                  {{ shipping }}
                </v-chip>
              </div>
              <v-combobox
                v-model="shippingType"
                :items="displayNotExistShippingTypes"
                :placeholder="$t('shipping.selectShipping.placeholder')"
                style="max-width: 200px;"
                attach
                outlined
                dense
                hide-details
                @change="selectShippingType($event)">
              </v-combobox>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="mt-3"
          no-gutters>
          <v-col cols="6">
            <select-form
              v-model="setting.defaultShippingType"
              :text="$t('shipping.selectShipping.defaultShippingType')"
              :items="setting.shippingTypes"
              :state="state.defaultShippingType"
              :attach="true"
              class="default-shipping-selection ml-12 mr-4"
              @input="submit('defaultShippingType')">
            </select-form>
          </v-col>
        </v-row>
        <v-row
          v-if="isShipnity"
          class="mt-3"
          no-gutters>
          <v-col cols="10">
            <toggle-form
              v-model="setting.autoDropoff"
              :text="$t('shipping.selectShipping.autoDropoff')"
              :state="state.autoDropoff"
              class="ml-12"
              @input="submit('autoDropoff')" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-if="isShipnity"
      class="mb-4"
      flat>
      <v-card-text>
        <title-label
          :label="$t('shipping.shippingConnection.title')"
          prepend-icon="$connectTransportTitle" />
        <div class="mt-4 pl-12 pr-12">
          <connect-jt
            :jt-name.sync="setting.jtMemberName"
            :jt-code.sync="setting.jtMemberCode"
            :jt-address.sync="setting.address">
          </connect-jt>
        </div>
        <div class="mt-4 pl-12 pr-12">
          <connect-dhl
            :dhl-code.sync="setting.dhlShippingId"
            :shipjung-token.sync="setting.shipjungToken">
          </connect-dhl>
        </div>
        <div class="mt-4 pl-12 pr-12">
          <connect-scg :scg-code.sync="setting.scgMemberCode" />
        </div>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <title-label
          :label="$t('shipping.trackingLink.title')"
          prepend-icon="$linkTitle" />
        <div class="pl-12 mt-3">
          <div class="d-flex align-center">
            <text-field-label
              :label="$t('shipping.trackingLink.link')"
              class="mr-3">
            </text-field-label>
            <a
              v-if="setting.shopurl"
              :href="`https://www.shipnity.com/tracking/${setting.shopurl}`"
              target="_blank"
              class="font-weight-medium">
              https://www.shipnity.com/tracking/<span>{{ setting.shopurl }}</span>
            </a>
            <v-btn
              to="/sale-page"
              class="ml-1"
              color="primary"
              icon
              small>
              <v-icon size="20">
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
          </div>
          <v-row
            class="mt-3"
            no-gutters>
            <v-col cols="7">
              <select-form
                v-model="setting.trackingOption"
                :text="$t('shipping.trackingLink.layout')"
                :items="trackingOption"
                :state="state.trackingOption"
                :attach="true"
                :menu-props="{ top: true, offsetY: true }"
                item-text="description"
                item-value="name"
                class="tracking-option-selection"
                @input="submit('trackingOption')">
              </select-form>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-card flat>
      <v-card-text>
        <div class="d-flex justify-space-between mr-12">
          <text-field-label
            :label="$t('shipping.cod.title')">
          </text-field-label>
          <v-btn
            class="mb-1 mr-6"
            color="primary"
            text
            @click="expand = !expand">
            <text-field-label
              v-if="expand"
              :label="$t('shipping.btn.cancel')"
              style="color: var(--v-primary-base) !important;"
              class="mt-2">
            </text-field-label>
            <text-field-label
              v-else
              :label="$t('shipping.btn.edit')"
              style="color: var(--v-primary-base) !important;"
              class="mt-2">
            </text-field-label>
          </v-btn>
        </div>
        <v-expand-transition>
          <div
            v-if="expand"
            class="mt-4">
            <div style="max-width: 70%;">
              <v-text-field
                v-model="setting.customerCodRate"
                class="cod-input"
                suffix="%"
                dense
                outlined
                hide-details />
              <div class="text-right">
                <save-change-button
                  class="mt-3"
                  :state="state.customerCodRate"
                  :label="$t('shipping.btn.save')"
                  @click="submit('customerCodRate')">
                </save-change-button>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ConnectJt from '@/views/Shipping/components/ShippingManage/ConnectJT'
import ConnectDhl from '@/views/Shipping/components/ShippingManage/ConnectDhl'
import ConnectScg from '@/views/Shipping/components/ShippingManage/ConnectScg'
import { GET_SETTING_SHIPPING } from '@/resources/graphql/query/Setting'
import { GET_TRACKINGOPTION_ENUM } from '@/resources/graphql/query/Enum'
import { UPDATE_SETTING } from '@/resources/graphql'
import { mapActions } from 'vuex'

export default {
  name: 'ShippingManage',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHIPPING,
      result () {
        this.setLoading = false
      }
    }),
    tracking: () => ({
      query: GET_TRACKINGOPTION_ENUM,
      result ({ data: { __type } }) {
        this.trackingOption = __type.enumValues
      },
      update: (data) => data.__type
    })
  },
  components: {
    ConnectJt,
    ConnectDhl,
    ConnectScg
  },
  data () {
    return {
      setting: {},
      expand: false,
      shippingType: '',
      trackingOption: [],
      state: {
        defaultShippingType: 'ready',
        autoDropoff: 'ready',
        trackingOption: 'ready',
        customerCodRate: 'ready'
      }
    }
  },
  computed: {
    displayNotExistShippingTypes () {
      if (this.setting.selectableShippingTypes) {
        return this.setting.selectableShippingTypes.filter((item) => !this.setting.shippingTypes.includes(item))
      }
      return []
    },
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    },
    isShipnity () {
      return this.domainName === 'shipnity.com' || this.domainName === 'localhost'
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    async selectShippingType (val) {
      this.setting.shippingTypes.push(val)
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            shippingTypes: this.setting.shippingTypes
          }
        }
      }).catch(() => {})
      if (res) {
        this.shippingType = ''
      }
    },
    async removeShippingTypes (index) {
      this.setting.shippingTypes.splice(index, 1)
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            shippingTypes: this.setting.shippingTypes
          }
        }
      }).catch(() => {})
      if (res) {
        this.$emit('submit')
      }
    },
    // async createShippingTypes () {
    //   console.log('create')
    //   if (this.shippingType) {
    //     this.setting.shippingTypes.push(this.shippingType)
    //     const res = await this.$apollo.mutate({
    //       mutation: UPDATE_SETTING,
    //       variables: {
    //         input: {
    //           shippingTypes: this.setting.shippingTypes
    //         }
    //       }
    //     }).catch(() => {})
    //     if (res) {
    //       this.$emit('input')
    //       this.shippingType = ''
    //     }
    //   }
    // },
    async submit (field) {
      this.state[field] = 'loading'
      this.setting.customerCodRate = Number(this.setting.customerCodRate)
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        this.state[field] = 'success'
      }
      setTimeout(() => {
        this.state[field] = 'ready'
      }, 3000)
    }
  }
}
</script>

<style>
  .shipping-manage .v-tabs-items {
    background-color: #F1F2F5;
  }

  .shipping-manage .v-expansion-panel-content__wrap {
    padding: 0 24px 0px;
  }

  .shipping-manage .cod-input input{
    text-align: right;
  }

  .default-shipping-selection .v-select {
    max-width: 200px;
  }

  .tracking-option-selection .v-select {
    max-width: 200px;
  }
</style>

<style scoped>
  .shipping-type {
    max-width: 50%;
    height: 95px;
    overflow-y: auto;
  }
</style>
