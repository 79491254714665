import gql from 'graphql-tag'

export const GET_SHIPPING_CALCULATOR = gql`
  query ($id: ID!) {
    shippingCalculator(id: $id) {
      id
      calculationMethod
      orderedShippingCalculations {
        fee
        id
        value
      }
    }
  }
`

export default {
  GET_SHIPPING_CALCULATOR
}
