import gql from 'graphql-tag'

export const GET_SHIPPING_CALCULATORS = gql`
  query ($page: Int, $perPage: Int) {
    shippingCalculators(page: $page, perPage: $perPage) {
      nodes {
        calculationMethod
        shippingType
        id
      }
      pagesCount
      nodesCount
    }
  }
`

export default {
  GET_SHIPPING_CALCULATORS
}
