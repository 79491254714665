<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <text-field-label
          :label="$t('shipping.shippingConnection.J&T.title')"
          class="mr-3">
        </text-field-label>
        <v-btn
          v-if="expand"
          :href="`https://www.${domainName}/jt_instruction.png`"
          target="_blank"
          color="primary"
          outlined>
          {{ $t('shipping.btn.howto') }}
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <text-field-label
          v-if="jtName && jtCode"
          :label="$t('shipping.btn.active')"
          class="mr-2">
        </text-field-label>
        <text-field-label
          v-else
          :label="$t('shipping.btn.close')"
          class="mr-2">
        </text-field-label>
        <v-btn
          class="mb-1 mr-6"
          color="primary"
          text
          @click="expand = !expand">
          <text-field-label
            v-if="expand"
            :label="$t('shipping.btn.cancel')"
            style="color: var(--v-primary-base) !important;"
            class="mt-2">
          </text-field-label>
          <text-field-label
            v-else
            :label="$t('shipping.btn.active')"
            style="color: var(--v-primary-base) !important;"
            class="mt-2">
          </text-field-label>
        </v-btn>
      </div>
    </div>
    <v-expand-transition>
      <div v-if="expand">
        <v-row
          class="pl-12 mt-4 mb-4"
          no-gutters>
          <v-col
            cols="4"
            class="pl-8">
            <text-field-label
              :label="$t('shipping.shippingConnection.J&T.name')">
            </text-field-label>
          </v-col>
          <v-col>
            <v-text-field
              :value="jtName"
              style="max-width: 300px;"
              class="input-readonly"
              dense
              outlined
              hide-details
              readonly />
          </v-col>
        </v-row>
        <v-row
          class="pl-12 mt-4 mb-4"
          no-gutters>
          <v-col
            cols="4"
            class="pl-8">
            <text-field-label
              :label="$t('shipping.shippingConnection.J&T.code')">
            </text-field-label>
          </v-col>
          <v-col>
            <v-text-field
              :value="jtCode"
              style="max-width: 300px;"
              class="input-readonly"
              dense
              outlined
              hide-details
              readonly />
          </v-col>
        </v-row>
        <v-row
          class="pl-12 mt-4 mb-4"
          no-gutters>
          <v-col
            cols="4"
            class="pl-8">
            <register-jt
              :name="jtName"
              :sid="jtCode"
              :address="jtAddress"
            >
            </register-jt>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>

import RegisterJt from '@/views/Shipping/components/ShippingManage/RegisterJT'

export default {
  name: 'ConnectJT',
  props: {
    jtName: {
      type: String,
      default: ''
    },
    jtCode: {
      type: String,
      default: ''
    },
    jtAddress: {
      type: String,
      default: ''
    }
  },
  components: {
    RegisterJt
  },
  data () {
    return {
      expand: false
    }
  },
  computed: {
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    }
  }
}
</script>

<style>
  .input-readonly .v-input_slot:focus {
    border-color: red;
  }
</style>
