var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shipping-manage" },
    [
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("shipping.selectShipping.title"),
                  "prepend-icon": "$transportTitle"
                }
              }),
              _c(
                "v-row",
                { staticClass: "pl-12" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("text-field-label", {
                        attrs: {
                          label: _vm.$t("shipping.selectShipping.shippingTypes")
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "div",
                          { staticClass: "shipping-type mr-4" },
                          _vm._l(_vm.setting.shippingTypes, function(
                            shipping,
                            index
                          ) {
                            return _c(
                              "v-chip",
                              {
                                key: "shipping_types-" + shipping + "-" + index,
                                staticClass: "mb-2 mr-2",
                                attrs: {
                                  color: "primary",
                                  close: "",
                                  label: ""
                                },
                                on: {
                                  "click:close": function($event) {
                                    return _vm.removeShippingTypes(index)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(shipping) + " ")]
                            )
                          }),
                          1
                        ),
                        _c("v-combobox", {
                          staticStyle: { "max-width": "200px" },
                          attrs: {
                            items: _vm.displayNotExistShippingTypes,
                            placeholder: _vm.$t(
                              "shipping.selectShipping.placeholder"
                            ),
                            attach: "",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.selectShippingType($event)
                            }
                          },
                          model: {
                            value: _vm.shippingType,
                            callback: function($$v) {
                              _vm.shippingType = $$v
                            },
                            expression: "shippingType"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("select-form", {
                        staticClass: "default-shipping-selection ml-12 mr-4",
                        attrs: {
                          text: _vm.$t(
                            "shipping.selectShipping.defaultShippingType"
                          ),
                          items: _vm.setting.shippingTypes,
                          state: _vm.state.defaultShippingType,
                          attach: true
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("defaultShippingType")
                          }
                        },
                        model: {
                          value: _vm.setting.defaultShippingType,
                          callback: function($$v) {
                            _vm.$set(_vm.setting, "defaultShippingType", $$v)
                          },
                          expression: "setting.defaultShippingType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isShipnity
                ? _c(
                    "v-row",
                    { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("toggle-form", {
                            staticClass: "ml-12",
                            attrs: {
                              text: _vm.$t(
                                "shipping.selectShipping.autoDropoff"
                              ),
                              state: _vm.state.autoDropoff
                            },
                            on: {
                              input: function($event) {
                                return _vm.submit("autoDropoff")
                              }
                            },
                            model: {
                              value: _vm.setting.autoDropoff,
                              callback: function($$v) {
                                _vm.$set(_vm.setting, "autoDropoff", $$v)
                              },
                              expression: "setting.autoDropoff"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.isShipnity
        ? _c(
            "v-card",
            { staticClass: "mb-4", attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("title-label", {
                    attrs: {
                      label: _vm.$t("shipping.shippingConnection.title"),
                      "prepend-icon": "$connectTransportTitle"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-4 pl-12 pr-12" },
                    [
                      _c("connect-jt", {
                        attrs: {
                          "jt-name": _vm.setting.jtMemberName,
                          "jt-code": _vm.setting.jtMemberCode,
                          "jt-address": _vm.setting.address
                        },
                        on: {
                          "update:jtName": function($event) {
                            return _vm.$set(_vm.setting, "jtMemberName", $event)
                          },
                          "update:jt-name": function($event) {
                            return _vm.$set(_vm.setting, "jtMemberName", $event)
                          },
                          "update:jtCode": function($event) {
                            return _vm.$set(_vm.setting, "jtMemberCode", $event)
                          },
                          "update:jt-code": function($event) {
                            return _vm.$set(_vm.setting, "jtMemberCode", $event)
                          },
                          "update:jtAddress": function($event) {
                            return _vm.$set(_vm.setting, "address", $event)
                          },
                          "update:jt-address": function($event) {
                            return _vm.$set(_vm.setting, "address", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4 pl-12 pr-12" },
                    [
                      _c("connect-dhl", {
                        attrs: {
                          "dhl-code": _vm.setting.dhlShippingId,
                          "shipjung-token": _vm.setting.shipjungToken
                        },
                        on: {
                          "update:dhlCode": function($event) {
                            return _vm.$set(
                              _vm.setting,
                              "dhlShippingId",
                              $event
                            )
                          },
                          "update:dhl-code": function($event) {
                            return _vm.$set(
                              _vm.setting,
                              "dhlShippingId",
                              $event
                            )
                          },
                          "update:shipjungToken": function($event) {
                            return _vm.$set(
                              _vm.setting,
                              "shipjungToken",
                              $event
                            )
                          },
                          "update:shipjung-token": function($event) {
                            return _vm.$set(
                              _vm.setting,
                              "shipjungToken",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4 pl-12 pr-12" },
                    [
                      _c("connect-scg", {
                        attrs: { "scg-code": _vm.setting.scgMemberCode },
                        on: {
                          "update:scgCode": function($event) {
                            return _vm.$set(
                              _vm.setting,
                              "scgMemberCode",
                              $event
                            )
                          },
                          "update:scg-code": function($event) {
                            return _vm.$set(
                              _vm.setting,
                              "scgMemberCode",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("shipping.trackingLink.title"),
                  "prepend-icon": "$linkTitle"
                }
              }),
              _c(
                "div",
                { staticClass: "pl-12 mt-3" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("text-field-label", {
                        staticClass: "mr-3",
                        attrs: { label: _vm.$t("shipping.trackingLink.link") }
                      }),
                      _vm.setting.shopurl
                        ? _c(
                            "a",
                            {
                              staticClass: "font-weight-medium",
                              attrs: {
                                href:
                                  "https://www.shipnity.com/tracking/" +
                                  _vm.setting.shopurl,
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(" https://www.shipnity.com/tracking/"),
                              _c("span", [_vm._v(_vm._s(_vm.setting.shopurl))])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            to: "/sale-page",
                            color: "primary",
                            icon: "",
                            small: ""
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(" mdi-square-edit-outline ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "7" } },
                        [
                          _c("select-form", {
                            staticClass: "tracking-option-selection",
                            attrs: {
                              text: _vm.$t("shipping.trackingLink.layout"),
                              items: _vm.trackingOption,
                              state: _vm.state.trackingOption,
                              attach: true,
                              "menu-props": { top: true, offsetY: true },
                              "item-text": "description",
                              "item-value": "name"
                            },
                            on: {
                              input: function($event) {
                                return _vm.submit("trackingOption")
                              }
                            },
                            model: {
                              value: _vm.setting.trackingOption,
                              callback: function($$v) {
                                _vm.$set(_vm.setting, "trackingOption", $$v)
                              },
                              expression: "setting.trackingOption"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between mr-12" },
                [
                  _c("text-field-label", {
                    attrs: { label: _vm.$t("shipping.cod.title") }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1 mr-6",
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.expand = !_vm.expand
                        }
                      }
                    },
                    [
                      _vm.expand
                        ? _c("text-field-label", {
                            staticClass: "mt-2",
                            staticStyle: {
                              color: "var(--v-primary-base) !important"
                            },
                            attrs: { label: _vm.$t("shipping.btn.cancel") }
                          })
                        : _c("text-field-label", {
                            staticClass: "mt-2",
                            staticStyle: {
                              color: "var(--v-primary-base) !important"
                            },
                            attrs: { label: _vm.$t("shipping.btn.edit") }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-expand-transition", [
                _vm.expand
                  ? _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "div",
                        { staticStyle: { "max-width": "70%" } },
                        [
                          _c("v-text-field", {
                            staticClass: "cod-input",
                            attrs: {
                              suffix: "%",
                              dense: "",
                              outlined: "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.setting.customerCodRate,
                              callback: function($$v) {
                                _vm.$set(_vm.setting, "customerCodRate", $$v)
                              },
                              expression: "setting.customerCodRate"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c("save-change-button", {
                                staticClass: "mt-3",
                                attrs: {
                                  state: _vm.state.customerCodRate,
                                  label: _vm.$t("shipping.btn.save")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.submit("customerCodRate")
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }