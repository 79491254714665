<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          outlined
          class="my-4"
          v-bind="attrs"
          v-on="on">
          {{ $t('shipping.shippingConnection.J&T.register') }}
        </v-btn>
      </template>
      <v-card v-if="isRegistered">
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closeDialog()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <label class="subtitle-1 font-weight-medium">
            {{ $t('shipping.shippingConnection.J&T.registered-title') }}
          </label>
          <v-row
            class="mt-4 mb-4"
            no-gutters>
            <v-col
              cols="4">
              <text-field-label
                :label="$t('shipping.shippingConnection.J&T.registered-status')">
              </text-field-label>
            </v-col>
            <v-col>
              <v-text-field
                :value="jtRegisteredStatus"
                class="jt-register-input-readonly"
                dense
                outlined
                hide-details
                readonly />
            </v-col>
          </v-row>
          <v-row
            v-if="status.siteName"
            class="mt-4 mb-4"
            no-gutters>
            <v-col
              cols="4">
              <text-field-label
                :label="$t('shipping.shippingConnection.J&T.registered-branch-name')">
              </text-field-label>
            </v-col>
            <v-col>
              <v-text-field
                :value="status.siteName"
                class="jt-register-input-readonly"
                dense
                outlined
                hide-details
                readonly />
            </v-col>
          </v-row>
          <v-row
            v-if="status.siteCode"
            class="mt-4 mb-4"
            no-gutters>
            <v-col
              cols="4">
              <text-field-label
                :label="$t('shipping.shippingConnection.J&T.registered-branch-id')">
              </text-field-label>
            </v-col>
            <v-col>
              <v-text-field
                :value="status.siteCode"
                class="jt-register-input-readonly"
                dense
                outlined
                hide-details
                readonly />
            </v-col>
          </v-row>
          <v-row
            v-if="status.sitePhone"
            class="mt-4 mb-4"
            no-gutters>
            <v-col
              cols="4">
              <text-field-label
                :label="$t('shipping.shippingConnection.J&T.registered-branch-tel')">
              </text-field-label>
            </v-col>
            <v-col>
              <v-text-field
                :value="status.sitePhone"
                class="jt-register-input-readonly"
                dense
                outlined
                hide-details
                readonly />
            </v-col>
          </v-row>
          <v-row
            v-if="status.siteAddress"
            class="mt-4 mb-4"
            no-gutters>
            <v-col
              cols="4">
              <text-field-label
                :label="$t('shipping.shippingConnection.J&T.registered-branch-address')">
              </text-field-label>
            </v-col>
            <v-col>
              <v-textarea
                :value="status.siteAddress"
                class="jt-register-input-readonly"
                rows="4"
                dense
                outlined
                hide-details
                readonly />
            </v-col>
          </v-row>
          <div
            class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              @click="closeDialog()">
              {{ $t('shipping.btn.ok') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closeDialog()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <label class="subtitle-1 font-weight-medium">
            {{ $t('shipping.shippingConnection.J&T.register-title') }}
          </label>
          <v-row
            class="mt-4 mb-4"
            no-gutters>
            <v-col
              cols="4">
              <text-field-label
                :label="$t('shipping.shippingConnection.J&T.register-name')">
              </text-field-label>
            </v-col>
            <v-col>
              <v-text-field
                :value="name"
                class="jt-register-input-readonly"
                dense
                outlined
                hide-details
                readonly />
            </v-col>
          </v-row>
          <v-row
            class="mt-4 mb-4"
            no-gutters>
            <v-col
              cols="4">
              <text-field-label
                :label="$t('shipping.shippingConnection.J&T.register-code')">
              </text-field-label>
            </v-col>
            <v-col>
              <v-text-field
                :value="sid"
                class="jt-register-input-readonly"
                dense
                outlined
                hide-details
                readonly />
            </v-col>
          </v-row>
          <v-row
            class="mt-4 mb-4"
            no-gutters>
            <v-col
              cols="4">
              <text-field-label
                :label="$t('shipping.shippingConnection.J&T.address')">
              </text-field-label>
            </v-col>
            <v-col>
              <v-textarea
                :value="address"
                class="jt-register-input-readonly"
                rows="4"
                dense
                outlined
                hide-details
                readonly />
            </v-col>
          </v-row>
          <div
            class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              text
              @click="closeDialog()">
              {{ $t('shipping.btn.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="submit()">
              {{ $t('shipping.btn.confirm') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_JT_REGISTER_STATUS } from '@/resources/graphql/query/Setting'
import {
  SUBMIT_JT_REGISTER
} from '@/resources/graphql'
import _ from 'lodash'

export default {
  name: 'RegisterJT',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_JT_REGISTER_STATUS,
      result ({ data: { setting } }) {
        this.status = setting.jtRegisterStatus
      }
    })
  },
  props: {
    name: {
      type: String,
      required: true
    },
    address: {
      type: String,
      default: ''
    },
    sid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      menu: false,
      dialog: false,
      status: {},
      state: 'ready',
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    },
    isRegistered() {
      return this.status.reason != "B084"
    },
    jtRegisteredStatus() {
      if (this.status.status == "SUCCESS") {
        return this.status.status
      } else {
        return `${this.status.status} (${this.status.reason})`
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      this.state = 'loading'
      const submitResponse = await this.$apollo.mutate({
        mutation: SUBMIT_JT_REGISTER,
        variables: {
          input: {
            jtCode: "abc"
          }
        }
      }).catch((err) => {
        this.state = 'error'
      })

      if (submitResponse) {
        this.state = 'success'
        this.status = submitResponse.data.jtRegister.output
      }
      setTimeout(() => {
        this.state = 'ready'
      }, 3000)
    }
  }
}
</script>

<style>
  .jt-register-input-readonly {
    background-color: #F1F2F5;
  }
</style>