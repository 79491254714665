<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="shippingCalculators.nodes"
      class="app-table"
      hide-default-footer>
      <template v-slot:[`header.shippingType`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.calculationMethod`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`item.calculationMethod`]="{ item }">
        {{ checkCalculationMethod(item) }}
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <calculate-update
          :shipping-calculate-id="item.id"
          :calculation-method.sync="item.calculationMethod"
          @submit="refetch()">
        </calculate-update>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="shippingCalculators.pagesCount"
      :total-visible="7">
    </v-pagination>
  </div>
</template>

<script>
import CalculateUpdate from '@/views/Shipping/components/ShippingCalculate/CalculateUpdate'
import { GET_SHIPPING_CALCULATORS } from '@/resources/graphql/query/ShippingCalculators'
import { mapActions } from 'vuex'

export default {
  name: 'CalculateDataTable',
  apollo: {
    shippingCalculators: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SHIPPING_CALCULATORS,
      variables () {
        return {
          page: this.page,
          perPage: this.perPage
        }
      },
      result () {
        this.setLoading = false
      }
    })
  },
  components: {
    CalculateUpdate
  },
  data () {
    return {
      shippingCalculators: [],
      page: 1,
      perPage: 5,
      headers: [
        {
          text: this.$t('shipping.table.header.type'),
          align: 'start',
          value: 'shippingType',
          sortable: false
        },
        {
          text: this.$t('shipping.table.header.howto'),
          align: 'start',
          value: 'calculationMethod',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'edit',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    refetch () {
      this.$apollo.queries.shippingCalculators.refetch()
    },
    checkCalculationMethod (item) {
      if (item.calculationMethod === 'price_based') {
        return 'คิดตามราคา'
      } if (item.calculationMethod === 'quantity_based') {
        return 'คิดตามจำนวน'
      }
      return 'คิดตามน้ำหนัก'
    }
  }
}
</script>
