<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <text-field-label
          :label="$t('shipping.shippingConnection.DHL.title')"
          class="mr-3">
        </text-field-label>
        <v-btn
          v-if="expand"
          href="https://shipjung.com"
          target="_blank"
          color="primary"
          outlined>
          {{ $t('shipping.btn.howto') }}
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <text-field-label
          v-if="dhlCode && shipjungToken"
          :label="$t('shipping.btn.active')"
          class="mr-2">
        </text-field-label>
        <text-field-label
          v-else
          :label=" $t('shipping.btn.close')"
          class="mr-2">
        </text-field-label>
        <v-btn
          class="mb-1 mr-6"
          color="primary"
          text
          @click="expand = !expand">
          <text-field-label
            v-if="expand"
            :label="$t('shipping.btn.cancel')"
            style="color: var(--v-primary-base) !important;"
            class="mt-2">
          </text-field-label>
          <text-field-label
            v-else
            :label="$t('shipping.btn.active')"
            style="color: var(--v-primary-base) !important;"
            class="mt-2">
          </text-field-label>
        </v-btn>
      </div>
    </div>
    <v-expand-transition>
      <div v-if="expand">
        <v-row
          class="pl-12 mt-4 mb-4"
          no-gutters>
          <v-col
            cols="4"
            class="pl-8">
            <text-field-label
              :label="$t('shipping.shippingConnection.DHL.code')">
            </text-field-label>
          </v-col>
          <v-col>
            <v-text-field
              :value="dhlCode"
              style="max-width: 300px;"
              dense
              outlined
              hide-details
              @input="updateDhlForm('dhlCode', $event)" />
            <div class="d-flex mt-4">
              <span class="caption mr-4">
                {{ $t('shipping.shippingConnection.DHL.hint') }}
              </span>
              <a
                href="https://shipjung.com/"
                target="_blank">
                <v-btn
                  color="primary"
                  outlined
                  x-small>
                  Shipjung
                </v-btn>
              </a>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="pl-12 mt-4 mb-4"
          no-gutters>
          <v-col
            cols="4"
            class="pl-8">
            <text-field-label
              :label="$t('shipping.shippingConnection.DHL.token')">
            </text-field-label>
          </v-col>
          <v-col>
            <v-text-field
              :value="shipjungToken"
              style="max-width: 300px;"
              dense
              outlined
              hide-details
              @input="updateDhlForm('shipjungToken', $event)" />
            <save-change-button
              class="mt-3"
              :state="state"
              :label="$t('shipping.btn.save')"
              @click="submit()">
            </save-change-button>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'ConnectDhl',
  props: {
    dhlCode: {
      type: [String, Number],
      default: ''
    },
    shipjungToken: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      expand: false,
      state: 'ready'
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    updateDhlForm (name, val) {
      this.$emit(`update:${name}`, val)
    },
    async submit () {
      this.state = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            dhlShippingId: this.dhlCode,
            shipjungToken: this.shipjungToken
          }
        }
      }).catch(() => {
        this.state = 'error'
      })
      if (res) {
        this.state = 'success'
      }
      setTimeout(() => {
        this.state = 'ready'
      }, 3000)
    }
  }
}
</script>

<style>

</style>
