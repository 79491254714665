var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "my-4",
                          attrs: { color: "primary", outlined: "" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("shipping.btn.editCost")) + " "
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "pt-3" },
                [
                  _c(
                    "div",
                    { staticClass: "close-popup" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeCalculateUpdate()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "label",
                    { staticClass: "subtitle-1 font-weight-medium" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("shipping.howToCalculate.title")) +
                          " "
                      )
                    ]
                  ),
                  _c("v-select", {
                    staticClass: "mt-3",
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      items: _vm.calculationMethods,
                      "item-text": "name",
                      "item-value": "value",
                      attach: "",
                      dense: "",
                      outlined: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.calculation,
                      callback: function($$v) {
                        _vm.calculation = $$v
                      },
                      expression: "calculation"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between mt-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "subtitle-1 font-weight-medium mt-2" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("shipping.howToCalculate.shippingCost")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "offset-y": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var attrs = ref.attrs
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "primary",
                                            rounded: "",
                                            depressed: ""
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(" mdi-plus-circle ")
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("shipping.btn.addPrice")
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.menu,
                            callback: function($$v) {
                              _vm.menu = $$v
                            },
                            expression: "menu"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { "max-width": "250" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "close-popup" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { small: "", icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.closeMenu()
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("text-field-label", {
                                    attrs: { label: _vm.checkHeader }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      dense: "",
                                      outlined: "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.createForm.max,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.createForm,
                                          "max",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "createForm.max"
                                    }
                                  }),
                                  _c("text-field-label", {
                                    attrs: {
                                      label: _vm.$t(
                                        "shipping.table.header.cost"
                                      )
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      dense: "",
                                      outlined: "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.createForm.cost,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.createForm,
                                          "cost",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "createForm.cost"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3",
                                          attrs: {
                                            color: "primary",
                                            rounded: "",
                                            depressed: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addShipping()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("shipping.btn.addPrice")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.checkshippingCalculations && _vm.render
                    ? _c("v-simple-table", {
                        staticClass: "mt-3",
                        attrs: { height: "400", "fixed-header": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          " " + _vm._s(_vm.checkHeader) + " "
                                        )
                                      ]),
                                      _c("th", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "shipping.table.header.cost"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("th")
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.shippingCalculator
                                        .orderedShippingCalculations,
                                      function(item, index) {
                                        return _c(
                                          "tr",
                                          {
                                            key: "shipping_calculator-" + index,
                                            class: { "d-none": item.remove }
                                          },
                                          [
                                            _c("td", { staticClass: "mt-2" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-space-between align-center my-3"
                                                },
                                                [
                                                  index !== 0
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              class: {
                                                                "disabled--text":
                                                                  item.remove
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .shippingCalculator
                                                                      .orderedShippingCalculations[
                                                                      index - 1
                                                                    ].value
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              class: {
                                                                "disabled--text":
                                                                  item.remove
                                                              }
                                                            },
                                                            [_vm._v(" 0 ")]
                                                          )
                                                        ]
                                                      ),
                                                  _c("span", [_vm._v(" - ")]),
                                                  _c("v-text-field", {
                                                    staticStyle: {
                                                      "max-width": "70%"
                                                    },
                                                    attrs: {
                                                      disabled: item.remove,
                                                      type: "number",
                                                      dense: "",
                                                      outlined: "",
                                                      "hide-details": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.sortValue()
                                                      }
                                                    },
                                                    model: {
                                                      value: item.value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "value",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression: "item.value"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    disabled: item.remove,
                                                    type: "number",
                                                    dense: "",
                                                    outlined: "",
                                                    "hide-details": ""
                                                  },
                                                  model: {
                                                    value: item.fee,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "fee",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression: "item.fee"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "#4d4d4d",
                                                      icon: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeItem(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        " mdi-delete-outline "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2753144290
                        )
                      })
                    : _vm._e(),
                  _vm.checkshippingCalculations
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-end mt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeCalculateUpdate()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("shipping.btn.cancel")) +
                                  " "
                              )
                            ]
                          ),
                          _c("save-change-button", {
                            attrs: {
                              state: _vm.state,
                              label: _vm.$t("shipping.btn.save")
                            },
                            on: {
                              click: function($event) {
                                return _vm.submit()
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }