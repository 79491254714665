var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "app-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.shippingCalculators.nodes,
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header.shippingType",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.calculationMethod",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "item.calculationMethod",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(_vm.checkCalculationMethod(item)) + " ")
                ]
              }
            },
            {
              key: "item.edit",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("calculate-update", {
                    attrs: {
                      "shipping-calculate-id": item.id,
                      "calculation-method": item.calculationMethod
                    },
                    on: {
                      "update:calculationMethod": function($event) {
                        return _vm.$set(item, "calculationMethod", $event)
                      },
                      "update:calculation-method": function($event) {
                        return _vm.$set(item, "calculationMethod", $event)
                      },
                      submit: function($event) {
                        return _vm.refetch()
                      }
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("v-pagination", {
        attrs: {
          length: _vm.shippingCalculators.pagesCount,
          "total-visible": 7
        },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }