var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "my-4",
                          attrs: { color: "primary", outlined: "" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("shipping.shippingConnection.J&T.register")
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.isRegistered
            ? _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "close-popup" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDialog()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v(" mdi-close ")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "subtitle-1 font-weight-medium" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "shipping.shippingConnection.J&T.registered-title"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-4",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("text-field-label", {
                                attrs: {
                                  label: _vm.$t(
                                    "shipping.shippingConnection.J&T.registered-status"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "jt-register-input-readonly",
                                attrs: {
                                  value: _vm.jtRegisteredStatus,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.status.siteName
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mt-4 mb-4",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("text-field-label", {
                                    attrs: {
                                      label: _vm.$t(
                                        "shipping.shippingConnection.J&T.registered-branch-name"
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "jt-register-input-readonly",
                                    attrs: {
                                      value: _vm.status.siteName,
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      readonly: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.status.siteCode
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mt-4 mb-4",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("text-field-label", {
                                    attrs: {
                                      label: _vm.$t(
                                        "shipping.shippingConnection.J&T.registered-branch-id"
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "jt-register-input-readonly",
                                    attrs: {
                                      value: _vm.status.siteCode,
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      readonly: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.status.sitePhone
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mt-4 mb-4",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("text-field-label", {
                                    attrs: {
                                      label: _vm.$t(
                                        "shipping.shippingConnection.J&T.registered-branch-tel"
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "jt-register-input-readonly",
                                    attrs: {
                                      value: _vm.status.sitePhone,
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      readonly: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.status.siteAddress
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mt-4 mb-4",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("text-field-label", {
                                    attrs: {
                                      label: _vm.$t(
                                        "shipping.shippingConnection.J&T.registered-branch-address"
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-textarea", {
                                    staticClass: "jt-register-input-readonly",
                                    attrs: {
                                      value: _vm.status.siteAddress,
                                      rows: "4",
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      readonly: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end mt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDialog()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("shipping.btn.ok")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "close-popup" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDialog()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v(" mdi-close ")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "subtitle-1 font-weight-medium" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "shipping.shippingConnection.J&T.register-title"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-4",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("text-field-label", {
                                attrs: {
                                  label: _vm.$t(
                                    "shipping.shippingConnection.J&T.register-name"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "jt-register-input-readonly",
                                attrs: {
                                  value: _vm.name,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-4",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("text-field-label", {
                                attrs: {
                                  label: _vm.$t(
                                    "shipping.shippingConnection.J&T.register-code"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "jt-register-input-readonly",
                                attrs: {
                                  value: _vm.sid,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-4",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("text-field-label", {
                                attrs: {
                                  label: _vm.$t(
                                    "shipping.shippingConnection.J&T.address"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-textarea", {
                                staticClass: "jt-register-input-readonly",
                                attrs: {
                                  value: _vm.address,
                                  rows: "4",
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end mt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDialog()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("shipping.btn.cancel")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.submit()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("shipping.btn.confirm")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }