<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          outlined
          class="my-4"
          v-bind="attrs"
          v-on="on">
          {{ $t('shipping.btn.editCost') }}
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closeCalculateUpdate()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <label class="subtitle-1 font-weight-medium">
            {{ $t('shipping.howToCalculate.title') }}
          </label>
          <v-select
            v-model="calculation"
            :items="calculationMethods"
            item-text="name"
            item-value="value"
            style="max-width: 250px;"
            class="mt-3"
            attach
            dense
            outlined
            hide-details>
          </v-select>
          <div class="d-flex justify-space-between mt-3">
            <label class="subtitle-1 font-weight-medium mt-2">
              {{ $t('shipping.howToCalculate.shippingCost') }}
            </label>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="primary"
                  rounded
                  depressed
                  v-on="on">
                  <v-icon>
                    mdi-plus-circle
                  </v-icon>
                  <span>
                    {{ $t('shipping.btn.addPrice') }}
                  </span>
                </v-btn>
              </template>
              <v-card max-width="250">
                <v-card-text>
                  <div class="close-popup">
                    <v-btn
                      small
                      icon
                      @click="closeMenu()">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                  <text-field-label :label="checkHeader" />
                  <v-text-field
                    v-model.number="createForm.max"
                    type="number"
                    dense
                    outlined
                    hide-details />
                  <text-field-label :label="$t('shipping.table.header.cost')" />
                  <v-text-field
                    v-model.number="createForm.cost"
                    type="number"
                    dense
                    outlined
                    hide-details />
                  <div class="text-right">
                    <v-btn
                      class="mt-3"
                      color="primary"
                      rounded
                      depressed
                      @click="addShipping()">
                      {{ $t('shipping.btn.addPrice') }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <v-simple-table
            v-if="checkshippingCalculations && render"
            class="mt-3"
            height="400"
            fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    {{ checkHeader }}
                  </th>
                  <th>
                    {{ $t('shipping.table.header.cost') }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in shippingCalculator.orderedShippingCalculations"
                  :key="`shipping_calculator-${index}`"
                  :class="{ 'd-none' : item.remove }">
                  <td class="mt-2">
                    <!-- <v-row>
                      <v-col>
                        <v-text-field
                          v-model="item.value"
                          :disabled="item.remove"
                          dense
                          outlined
                          hide-details />
                      </v-col>
                      <v-col
                        v-if="index !== 0"
                        cols="1"
                        class="mt-2">
                        -
                      </v-col>
                      <v-col
                        v-if="index !== 0"
                        cols="4"
                        class="text-left mt-2">
                        <span
                          v-if="index !== 0"
                          class="mt-2 mr-2">
                          <span :class="{ 'disabled--text' : item.remove }">
                            {{ shippingCalculator.orderedShippingCalculations[index - 1].value }}
                          </span>
                        </span>
                        <span
                          v-else
                          class="mt-2 mr-2">
                          <span :class="{ 'disabled--text' : item.remove }">

                          </span>
                        </span>
                      </v-col>
                    </v-row> -->

                    <div class="d-flex justify-space-between align-center my-3">
                      <span
                        v-if="index !== 0"
                        class="mr-2">
                        <span :class="{ 'disabled--text' : item.remove }">
                          {{ shippingCalculator.orderedShippingCalculations[index - 1].value }}
                        </span>
                      </span>
                      <span
                        v-else
                        class="mr-2">
                        <span :class="{ 'disabled--text' : item.remove }">
                          0
                        </span>
                      </span>
                      <span>
                        -
                      </span>
                      <v-text-field
                        v-model.number="item.value"
                        :disabled="item.remove"
                        type="number"
                        style="max-width: 70%;"
                        dense
                        outlined
                        hide-details
                        @change="sortValue()" />
                    </div>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="item.fee"
                      :disabled="item.remove"
                      type="number"
                      dense
                      outlined
                      hide-details />
                  </td>
                  <td>
                    <v-btn
                      color="#4d4d4d"
                      icon
                      @click="removeItem(index)">
                      <v-icon>
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            v-if="checkshippingCalculations"
            class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              text
              @click="closeCalculateUpdate()">
              {{ $t('shipping.btn.cancel') }}
            </v-btn>
            <save-change-button
              :state="state"
              :label="$t('shipping.btn.save')"
              @click="submit()">
            </save-change-button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_SHIPPING_CALCULATOR } from '@/resources/graphql/query/ShippingCalculator'
import {
  CREATE_SHIPPING_CALCULATION,
  UPDATE_SHIPPING_CALCULATOR,
  UPDATE_SHIPPING_CALCULATION,
  DELETE_SHIPPING_CALCULATION
} from '@/resources/graphql'
import _ from 'lodash'

export default {
  name: 'CalculateUpdate',
  apollo: {
    shippingCalculator: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SHIPPING_CALCULATOR,
      variables () {
        return {
          id: this.shippingCalculateId
        }
      }
    })
  },
  props: {
    shippingCalculateId: {
      type: [String, Number],
      required: true
    },
    calculationMethod: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      menu: false,
      dialog: false,
      shippingCalculator: {},
      render: true,
      state: 'ready',
      calculationMethods: [
        {
          name: 'คิดตามราคา',
          value: 'price_based'
        },
        {
          name: 'คิดตามจำนวน',
          value: 'quantity_based'
        },
        {
          name: 'คิดตามน้ำหนัก',
          value: 'weight_based'
        }
      ],
      createForm: {
        max: 0,
        cost: 0
      },
      calculation: this.calculationMethod
    }
  },
  computed: {
    checkHeader () {
      if (this.calculation === 'price_based') {
        return this.$t('shipping.table.header.price')
      } if (this.calculation === 'quantity_based') {
        return this.$t('shipping.table.header.quantity')
      }
        return this.$t('shipping.table.header.weight')
    },
    checkshippingCalculations () {
      return this.shippingCalculator.orderedShippingCalculations && this.shippingCalculator.orderedShippingCalculations.length
    },
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    forceRerender () {
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    },
    closeMenu () {
      this.menu = false
    },
    removeItem (index) {
      this.shippingCalculator.orderedShippingCalculations[index].remove = true
      this.forceRerender()
    },
    undoItem (index) {
      this.shippingCalculator.orderedShippingCalculations[index].remove = false
      this.forceRerender()
    },
    closeCalculateUpdate () {
      this.dialog = false
    },
    refetch () {
      this.$apollo.queries.shippingCalculator.refetch()
    },
    sortValue () {
      const orderedShippingCalculations = this.shippingCalculator.orderedShippingCalculations
      this.shippingCalculator.orderedShippingCalculations = _.orderBy(orderedShippingCalculations, ['value'], ['asc'])
    },
    addShipping () {
      this.shippingCalculator.orderedShippingCalculations.unshift(
        {
          id: null,
          value: this.createForm.max,
          fee: this.createForm.cost,
          remove: false
        }
      )
      this.sortValue()
      this.createForm.max = 0
      this.createForm.cost = 0
      this.menu = false
    },
    async submit () {
      this.state = 'loading'
      const calculatorResponse = await this.$apollo.mutate({
        mutation: UPDATE_SHIPPING_CALCULATOR,
        variables: {
          input: {
            id: this.shippingCalculateId,
            calculationMethod: this.calculation
          }
        }
      }).catch(() => {
        this.state = 'error'
      })

      for (let i = 0; i < this.shippingCalculator.orderedShippingCalculations.length; i++) {
        if (!this.shippingCalculator.orderedShippingCalculations[i].id && !this.shippingCalculator.orderedShippingCalculations[i].remove) {
          this.createCalculation(i)
        }

        if (this.shippingCalculator.orderedShippingCalculations[i].id) {
          this.updateCalculation(i)
        }

        if (this.shippingCalculator.orderedShippingCalculations[i].id && this.shippingCalculator.orderedShippingCalculations[i].remove) {
          this.deleteCalculation(i)
        }
      }

      if (calculatorResponse) {
        this.$emit('submit')
        this.refetch()
        this.state = 'success'
      }
      setTimeout(() => {
        this.state = 'ready'
      }, 3000)
    },
    async createCalculation (index) {
      const res = await this.$apollo.mutate({
        mutation: CREATE_SHIPPING_CALCULATION,
        variables: {
          input: {
            shippingCalculatorId: this.shippingCalculateId,
            value: Number(this.shippingCalculator.orderedShippingCalculations[index].value),
            fee: Number(this.shippingCalculator.orderedShippingCalculations[index].fee)
          }
        }
      }).catch(() => {})
      if (res) {
        this.refetch()
      }
    },
    async updateCalculation (index) {
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SHIPPING_CALCULATION,
        variables: {
          input: {
            id: this.shippingCalculator.orderedShippingCalculations[index].id,
            value: Number(this.shippingCalculator.orderedShippingCalculations[index].value),
            fee: Number(this.shippingCalculator.orderedShippingCalculations[index].fee)
          }
        }
      }).catch(() => {})
      if (res) {
        this.refetch()
      }
    },
    async deleteCalculation (index) {
      const res = await this.$apollo.mutate({
        mutation: DELETE_SHIPPING_CALCULATION,
        variables: {
          input: {
            id: this.shippingCalculator.orderedShippingCalculations[index].id
          }
        }
      })
      if (res) {
        this.refetch()
      }
    }
  }
}
</script>
