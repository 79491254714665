var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("text-field-label", {
              staticClass: "mr-3",
              attrs: { label: _vm.$t("shipping.shippingConnection.DHL.title") }
            }),
            _vm.expand
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      href: "https://shipjung.com",
                      target: "_blank",
                      color: "primary",
                      outlined: ""
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("shipping.btn.howto")) + " ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _vm.dhlCode && _vm.shipjungToken
              ? _c("text-field-label", {
                  staticClass: "mr-2",
                  attrs: { label: _vm.$t("shipping.btn.active") }
                })
              : _c("text-field-label", {
                  staticClass: "mr-2",
                  attrs: { label: _vm.$t("shipping.btn.close") }
                }),
            _c(
              "v-btn",
              {
                staticClass: "mb-1 mr-6",
                attrs: { color: "primary", text: "" },
                on: {
                  click: function($event) {
                    _vm.expand = !_vm.expand
                  }
                }
              },
              [
                _vm.expand
                  ? _c("text-field-label", {
                      staticClass: "mt-2",
                      staticStyle: {
                        color: "var(--v-primary-base) !important"
                      },
                      attrs: { label: _vm.$t("shipping.btn.cancel") }
                    })
                  : _c("text-field-label", {
                      staticClass: "mt-2",
                      staticStyle: {
                        color: "var(--v-primary-base) !important"
                      },
                      attrs: { label: _vm.$t("shipping.btn.active") }
                    })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("v-expand-transition", [
        _vm.expand
          ? _c(
              "div",
              [
                _c(
                  "v-row",
                  {
                    staticClass: "pl-12 mt-4 mb-4",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pl-8", attrs: { cols: "4" } },
                      [
                        _c("text-field-label", {
                          attrs: {
                            label: _vm.$t(
                              "shipping.shippingConnection.DHL.code"
                            )
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticStyle: { "max-width": "300px" },
                          attrs: {
                            value: _vm.dhlCode,
                            dense: "",
                            outlined: "",
                            "hide-details": ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateDhlForm("dhlCode", $event)
                            }
                          }
                        }),
                        _c("div", { staticClass: "d-flex mt-4" }, [
                          _c("span", { staticClass: "caption mr-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("shipping.shippingConnection.DHL.hint")
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://shipjung.com/",
                                target: "_blank"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    "x-small": ""
                                  }
                                },
                                [_vm._v(" Shipjung ")]
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "pl-12 mt-4 mb-4",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pl-8", attrs: { cols: "4" } },
                      [
                        _c("text-field-label", {
                          attrs: {
                            label: _vm.$t(
                              "shipping.shippingConnection.DHL.token"
                            )
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticStyle: { "max-width": "300px" },
                          attrs: {
                            value: _vm.shipjungToken,
                            dense: "",
                            outlined: "",
                            "hide-details": ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateDhlForm("shipjungToken", $event)
                            }
                          }
                        }),
                        _c("save-change-button", {
                          staticClass: "mt-3",
                          attrs: {
                            state: _vm.state,
                            label: _vm.$t("shipping.btn.save")
                          },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }